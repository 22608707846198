import React from 'react';
import { useRouter } from 'next/router';
import { Text, IconButton, useConst, Box, Flex, Stack } from '@chakra-ui/react';
import IconOutlineMail from '../icons/OutlineMail';

import Link from 'components/Link';
import Logo from 'components/Logo';
import { Media, responsive } from '../../contexts/responsive';

const iconSize = {
  base: '2.5em',
  md: '3em',
  '2xl': '4em'
};

const Footer = ({ pageProps, fullpageApi, ...props }) => {
  const router = useRouter();
  const year = useConst(() => new Date().getFullYear());
  return (
    <Box
      as="footer"
      display={['none', 'block']}
      pos="relative"
      zIndex="base"
      pt="2.75rem"
      px="2em"
      pb="2rem"
      color="white"
      bg="black"
      {...props}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Flex flexDir="row">
          <Link
            href="/"
            onClick={() => {
              if (fullpageApi) {
                fullpageApi.moveTo(1);
              }
            }}
            fontSize="0"
            lineHeight="1"
          >
            <Logo variant="full" width="200px" />
          </Link>
          <Text ms={6} pb={5} fontSize="xl" fontWeight="bold">
            讓溝通變好玩
          </Text>
        </Flex>
        <Stack alignItems="flex-end">
          <Stack direction="row" spacing={4} alignItems="center">
            <Stack
              fontSize="md"
              direction="row"
              spacing={4}
              alignItems="center"
            >
              {router.pathname !== '/' && (
                <Link
                  href="/"
                  onClick={() => {
                    if (fullpageApi) {
                      fullpageApi.moveTo(1);
                    }
                  }}
                >
                  回首頁
                </Link>
              )}
              <Link href="/terms" target="_blank">
                條款與隱私權
              </Link>
            </Stack>
            <IconButton
              as={Link}
              href="mailto:ooopenlab@relab.cc"
              icon={<IconOutlineMail w={6} h={6} />}
              isRound
              width={12}
              height={12}
              fontSize={iconSize}
              border="1px solid white"
              aria-label="詢問"
            />
          </Stack>
          <Media greaterThanOrEqual="sm">
            <Text>
              Copyright © {year}&nbsp;
              <Link color="inherit" href="/">
                OOOPEN Lab
              </Link>
            </Text>
          </Media>
        </Stack>
      </Flex>
    </Box>
  );
};

export default Footer;
